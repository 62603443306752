<template>
  <div class="tab-content">
    <scroll class="brand-wrapper content">
      <ul class="brand-list">
        <li
          v-for="item in brandList"
          :key="item.id"
          class="item"
          :class="{ active: currentIndex == item.id }"
          @click="clickBrandHandle(item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </scroll>
    <scroll class="phone-model-wrapper content">
      <ul class="phone-model-list">
        <li
          v-for="(item, index) in phoneModelList"
          :key="item.id"
          class="item"
          :data-index="index"
          @click="goToEvaluation(item.id)"
        >
          <span class="tag">{{ index + 1 }}</span>
          <span class="title-name">{{ item.name }}</span>
        </li>
      </ul>
    </scroll>
  </div>

</template>
<script>
  import Scroll from 'components/common/scroll/Scroll'

  export default {
    name: 'MainScroll',
    props: {
      tabActive: {
        type: Number,
        default () {
          return ''
        }
      },
      brandList: {
        type: Array,
        default () {
          return []
        }
      },
      phoneModelList: {
        type: Array,
        default () {
          return []
        }
      },
      currentIndex: Number
    },
    created () {
    },
    methods: {
      clickBrandHandle (params) {
        this.$emit('selectBrand', params)
      },
      goToEvaluation (id) {
        this.$router.push({
          path: '/evaluation',
          query: {
            id
          }
        })
      }
    },
    components: {
      Scroll
    },
  }
</script>
<style lang="less" scoped>
  .tab-content {
    display: flex;
    flex-wrap: nowrap;
    height: calc(100vh - 147px);
    overflow: hidden;
    line-height: 1rem;
  }

  .brand-wrapper {
    flex: 0 0 1.6rem;
    height: 100%;

    .brand-list {
      background: #f8f8f8;

      .item {
        position: relative;
        width: 100%;
        height: 1rem;
        text-align: center;
        color: #000;
        font-size: 0.32rem;
        font-weight: 400;

        &.active {
          background: #fff;
          font-weight: bold;

          &::before {
            content: "";
            position: absolute;
            left: 0.05rem;
            bottom: 0.32rem;
            top: 0.36rem;
            width: 0.08rem;
            height: 0.25rem;
            background: #ff5100;
          }
        }
      }
    }
  }

  .phone-model-wrapper {
    flex: 1;
    height: 100%;

    .phone-model-list {
      padding-left: 0.2rem;
      background-color: #fff;

      .item {
        height: 1rem;
        display: flex;
        border-bottom: 1px solid #e5e5e5;
        align-items: center;

        .tag {
          flex: 0 0 0.4rem;
          height: 0.4rem;
          text-align: center;
          line-height: 0.4rem;
          background: rgb(223, 223, 223);
          border-radius: 0.02rem;
          color: #fff;
          margin-right: 0.2rem;
          font-size: 0.24rem;
        }

        &:first-child .tag {
          background-color: #ff5100;
        }

        &:nth-child(2) .tag {
          background-color: #ef9e67;
        }

        &:nth-child(3) .tag {
          background-color: #eecd46;
        }

        .title-name {
          width: 4.2rem;
          height: 1rem;
          line-height: 1rem;
          font-size: 0.3rem;
          font-family: PingFang SC;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
      }
    }
  }
</style>
