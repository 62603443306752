<template>
  <div class="page-container">
    <van-search
      v-model="searchValue"
      placeholder="请输入搜索关键词"
      shape="round"
      @focus="goToSearch"
    />
    <van-tabs v-model="tabActive" sticky @click="selectProductType(tabActive+1)">
      <van-tab :title="item.name" v-for="item in productType" :key="item.id"></van-tab>
    </van-tabs>
    <main-scroll
      :tabActive="tabActive"
      :brandList="brandList"
      :phoneModelList="phoneModelList"
      @selectBrand="selectBrand"
      :currentIndex="currentIndex"
    ></main-scroll>
    <main-tab-bar/>
  </div>
</template>

<script>
  import MainTabBar from 'components/content/mainTabbar/MainTabBar'
  import MainScroll from './childComps/MainScroll'

  import { getBrandList, getPhoneModelList, getProductType } from 'api/api'

  export default {
    name: 'category',
    data () {
      return {
        searchValue: '',
        tabActive: 0, //tabbar选择值
        productType: [],
        brandList: [],
        phoneModelList: [],
        currentIndex: null,
        isNeedRefresh: false
      }
    },

    components: {
      MainTabBar,
      MainScroll
    },
    created () {
      this.init()
    },
    methods: {
      async init () {
        this.getProductType()
        await this.getBrandList(this.tabActive + 1)
        //设置当前选中品牌
        this.currentIndex = Number(this.$route.query.index) || this.brandList[0].id
        this.getPhoneModelList(this.currentIndex)
      },
      //获取产品类型
      getProductType () {
        getProductType().then(res => {
          this.productType = res
        })
      },
      //获取品牌列表
      async getBrandList (type) {
        let params = {
          type
        }
        await getBrandList(params).then(res => {
          this.brandList = res
        })
      },
      //获取机型列表
      getPhoneModelList (id) {
        let params = {
          brand_id: id
        }

        getPhoneModelList(params).then(res => {
          this.phoneModelList = res
        })
      },
      //点击产品类型切换
      async selectProductType (type) {
        await this.getBrandList(type)
        //设置当前选中品牌为第一个品牌
        this.currentIndex = this.brandList[0].id
        this.getPhoneModelList(this.currentIndex)
      },
      //点击品牌切换型号
      selectBrand (params) {
        this.currentIndex = params
        this.getPhoneModelList(params)
      },
      //  跳转到搜索页面
      goToSearch () {
        this.$router.push('/search')
      },
    },
  }
</script>

<style lang="less" scoped>
  .page-container {
    padding-bottom: 49px;
  }
</style>
